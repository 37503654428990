module.exports = [
  {
    id: 'plant1',
    plant: 'crocus echinacea zinnia',
    latin: 'crocus echinacea zinnia',
    description: 'From snow crocuses (the first to bloom) to giant Dutch crocuses, all just 2 to 4 inches tall, these blooms offer a variety in color (pinks, reds, oranges, yellows, purples, blues, and more) that stand out against the bleak late-winter landscape. Many have strong perfumes that lure bees out of their hives in February or March.',
    link: 'https://www.rhs.org.uk/Plants/257246/Crocus-Prins-Claus/Details'
  },
  {
    id: 'plant2',
    plant: 'Hyacinth',
    latin: 'Hyacinthus',
    description: '',
    link: 'https://www.rhs.org.uk/plants/popular/hyacinth/growing-guide'
  },
  {
    id: 'plant3',
    plant: 'Indian grass',
    latin: 'Sorghastrum nutans',
    description: 'detoxifies common agro-chemical residues such as well-known pesticides and herbicides related to atrazine and metalochlor, as well as remediating petroleum hydrocarbons',
    link: 'https://www.rhs.org.uk/Plants/60634/i-Sorghastrum-nutans-i/Details'
  },
  {
    id: 'plant4',
    plant: 'sunflower',
    latin: '',
    description: 'Reduces heavy metals such as Pb, Zn and Canola, N, P, K, Cd, Cu or Mn',
    link: 'https://www.rhs.org.uk/Plants/238099/i-Helianthus-annuus-i-Earthwalker/Details'
  },
  {
    id: 'plant5',
    plant: 'Indian mustard',
    latin: 'Brassica Junca',
    description: 'It can remove three times more Cd than others, reduce 28% of Pb, up to 48% of Se, and it is effective against Zn, Hg and Cu as well.',
    link: 'https://homeguides.sfgate.com/grow-indian-mustard-29628.html'
  },
  {
    id: 'plant6',
    plant: 'spider plant',
    latin: '',
    description: '',
    link: ''
  },
  
  {
    id: 'plant7',
    plant: 'bee polinators',
    latin: '',
    description: 'Crocus, bee balm, zinnias',
    link: ''
  },
  {
    id: 'plant8',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  },
  {
    id: 'plant9',
    plant: 'crocus echinacea zinnia',
    latin: '',
    description: '',
    link: ''
  },
  {
    id: 'plant10',
    plant: 'Hyacinth',
    latin: 'Hyacinthus',
    description: '',
    link: 'https://www.rhs.org.uk/plants/popular/hyacinth/growing-guide'
  },
  {
    id: 'plant11',
    plant: 'Indian grass',
    latin: 'Sorghastrum nutans',
    description: 'detoxifies common agro-chemical residues such as well-known pesticides and herbicides related to atrazine and metalochlor, as well as remediating petroleum hydrocarbons',
    link: 'https://www.rhs.org.uk/Plants/60634/i-Sorghastrum-nutans-i/Details'
  },
  {
    id: 'plant12',
    plant: 'Sunflower',
    latin: '',
    description: 'Reduces heavy metals such as Pb, Zn and Canola, N, P, K, Cd, Cu or Mn',
    link: 'https://www.rhs.org.uk/Plants/238099/i-Helianthus-annuus-i-Earthwalker/Details'
  },
  {
    id: 'plant13',
    plant: 'Indian mustard',
    latin: 'Brassica Junca',
    description: 'It can remove three times more Cd than others, reduce 28% of Pb, up to 48% of Se, and it is effective against Zn, Hg and Cu as well.',
    link: 'https://homeguides.sfgate.com/grow-indian-mustard-29628.html'
  },
  {
    id: 'plant14',
    plant: 'spider plant',
    latin: '',
    description: '',
    link: ''
  },
  {
    id: 'plant15',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  },
  {
    id: 'plant16',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  },
  {
    id: 'plant17',
    plant: 'Ruderal',
    latin: '',
    description: '',
    link: ''
  },
  {
    id: 'plant18',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  },
  {
    id: 'plant19',
    plant: 'Sunflower',
    latin: '',
    description: 'Reduces heavy metals such as Pb, Zn and Canola, N, P, K, Cd, Cu or Mn',
    link: 'https://www.rhs.org.uk/Plants/238099/i-Helianthus-annuus-i-Earthwalker/Details'
  },
  {
    id: 'plant20',
    plant: 'Pteris cretica',
    latin: 'Pteris cretica',
    description: 'Fern, hyperaccumulator of arsenic.  Needs minimal maintenance and food/light.',
    link: 'https://www.rhs.org.uk/Plants/100589/i-Pteris-cretica-i/Details'
  },
  {
    id: 'plant21',
    plant: 'Dracaena deremsis',
    latin: 'Dracaena deremsis',
    description: 'Removes benzene from the air, a common product of exhaust.  Actually very good as an indoors',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3996197/'
  },
  {
    id: 'plant22',
    plant: 'Pteris cretica',
    latin: 'Pteris cretica',
    description: 'Fern, hyperaccumulator of arsenic.  Needs minimal maintenance and food/light.',
    link: 'https://www.rhs.org.uk/Plants/100589/i-Pteris-cretica-i/Details'
  },
  {
    id: 'plant23',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  },
  {
    id: 'plant24',
    plant: 'bee polinators',
    latin: 'Crocus, echinacea, zinnias',
    description: '',
    link: ''
  }
  
  
];