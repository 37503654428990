import React from "react";
import styled from "@emotion/styled";
import { Box } from "rebass";
import PlantData from "../data/plantData";
// console.log(PlantData);

const DescriptionBox = styled.div`
  height: 800px;
  /* background-color: red; */
  /* color: green; */
  color: black;
  a {
    color: gray;
  }
`;
const PlantDescription = React.forwardRef(({ id, plant }, ref) => {
  // console.log(index)

  const plantObject = PlantData.find(item => item.id === id);
  return (
    <DescriptionBox style={{ padding: `12px` }} ref={ref}>
      <Box fontSize={1}>
        <Box fontSize={2}>
          {plantObject.plant} <em>{plantObject.latin}</em>
        </Box>
        <p>{plantObject.description}</p>
        <p>
          <a href={plantObject.link}>{plantObject.link}</a>
        </p>
      </Box>
    </DescriptionBox>
  );
});

export default PlantDescription;
