import React from "react";
// import { format } from "date-fns";
// import styled from "@emotion/styled";
import { Box, Card } from "rebass";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import PlantData from "../data/plantData";

const scrollToRef = ref => {
  // console.log(ref)
  ref.current.scrollIntoView();
};

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}
var aDay = 24 * 60 * 60 * 1000;
console.log(timeSince(new Date(Date.now() - aDay)));
console.log(timeSince(new Date(Date.now() - aDay * 2)));

const PlantStatus = React.forwardRef(
  ({ id, lastWatered, watering, ping, plant, mini }, ref) => {
    // let formattedDate = format(new Date(lastWatered), "Do MMM YY - HH:mm:ss ");
    const plantObject = PlantData.find(item => item.id === id);

    var date = new Date(lastWatered);
    let difference = Math.floor(Date.now() - date);
    let timeAgo = timeSince(new Date(Date.now() - difference));

    let colour = watering ? "DodgerBlue" : mini ? "#333" : "black";
    if (ping) {
      colour = "slategray";
    }

    const myRef = ref;
    // console.log(myRef)
    const executeScroll = () => scrollToRef(myRef);

    return (
      <Box
        p={[0, 1, 1]}
        fontSize={1}
        // ref={ref}
        onClick={executeScroll}
        css={{
          cursor: "pointer"
        }}
      >
        <Card p={mini ? 1 : 1} bg={colour}>
          <Box px={mini ? 0 : 1}>
            {!mini && (
              <>
                <Box pb={2}>
                  <strong>
                    {plantObject.plant ? plantObject.plant : plantObject.latin}
                  </strong>
                </Box>
                <Box pb={2}>
                  {watering
                    ? "Watering plant"
                    : ping
                    ? "Checking status"
                    : "Soil moist"}
                </Box>

                <Box>
                  <div>Watered {lastWatered ? timeAgo : "8 hours ago"}</div>
                </Box>
              </>
            )}
            {/* <Text pb={2}>
            {watering ? "Watering right now" : "Pump off"}
          </Text> */}
          </Box>
        </Card>
      </Box>
    );
  }
);

export default PlantStatus;
