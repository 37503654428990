import React, { Component } from "react";
import "./App.css";
import * as firebase from "firebase";
import { css } from "emotion";
import styled from "@emotion/styled";
import { Box, Card } from "rebass";
import PlantStatus from "./components/PlantStatus";
import PlantDescription from "./components/PlantDescription";

let page;

const MaxWidth = "100vw";
const pumpImage = css`
  height: 10px;
`;

const MainWrapper = styled.div`
  margin: 0 auto;
  padding: 0%;
  max-width: ${MaxWidth};
  div {
    transition: background-color 0.5s ease;
  }
  h1 {
    font-size: 1.3em;
    margin: 0 0 10px 0;
    font-weight: 300;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 0.8em;
    font-weight: 300;
  }
  @media (min-width: 40em) {
    /* padding: 0px 1.0875rem 1.45rem; */
    padding: 0px;
    font-size: 100%;
  }
`;

const MiniSection = styled.div`
  /* width: 100vw;
  height: 20px;
  position: fixed;
  top: 0; */
  margin-bottom: 20px;
`;
//'40em', '52em', '64em'
const MainGrid = styled.div`
  display: grid;
  @media (orientation: landscape) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (orientation: portrait) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const WateringSection = styled.div`
  @media (orientation: landscape) {
    height: 100vh;
  }
  @media (orientation: portrait) {
    height: 60vh;
  }
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;
const DescriptionSection = styled.div`
  @media (orientation: landscape) {
    height: 100vh;
  }
  @media (orientation: portrait) {
    height: 40vh;
  }
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: #ddd;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
const DescriptionBox = styled.div`
  height: 300px;
  background-color: red;
  color: green;
`;

const GridBox = css``;
const PlantTop = styled.div`
  margin-bottom: 10px;
  color: white;
  &:hover {
    color: white;
  }
`;
const Title = styled.div`
  font-weight: normal;
`;

const Environment = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Navigation = styled.nav`
  a {
    color: white;
  }
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 15px;
  }
`;

let shouldChange = false;

function fixed(x) {
  return Number.parseFloat(x).toFixed(2);
}
//this[`ref_${plantObject.id}`].current.scrollIntoView()

class App extends Component {
  constructor() {
    super();
    this.state = {
      pumps: []
    };
  }

  componentDidMount() {
    const rootRef = firebase.database().ref("wateringStatus");
    const pumpsRef = rootRef.child("pumps");
    const envRef = rootRef.child("wellEnvironment");
    page = window.location.pathname;
    // console.log("location = ", page);
    // console.log(pumpsRef)
    pumpsRef.on("value", snap => {
      const val = snap.val();
      // console.log("val", val)
      // Object.keys(val).map(key => {
      //   this[`ref_${key}`] = React.createRef();
      //   console.log('made ref', key)
      // });
      this.setState(
        {
          pumps: Object.keys(val).map(key => {
            return {
              ...val[key],
              id: key
            };
          })
        },
        () => {
          this.state.pumps.forEach(item => {
            this[`ref_${item.id}`] = React.createRef();
            // console.log("ref is ", this[`ref_${item.id}`]);
          });
        }
      );
    });

    envRef.on("value", snap => {
      const val = snap.val();
      // console.log(val)
      this.setState({
        temperature: val.temperature,
        humidity: val.humidity,
        pressure: val.pressure
      });
    });

    const checkingTimer = () => {
      if (shouldChange) {
        // console.log("about to do it");
        let prob = Math.floor(Math.random() * 3);
        // console.log(prob)
        if (prob === 0) {
          turnOnWatering(Math.floor(Math.random() * this.state.pumps.length));
        }
      }
    };

    const pingTimer = () => {
      if (shouldChange) {
        // console.log("about to do it");
        let prob = Math.floor(Math.random() * 2);
        // console.log(prob)
        if (prob === 0) {
          pingOn(Math.floor(Math.random() * this.state.pumps.length));
        }
      }
    };

    const turnOnWatering = pump => {
      let newPumps = [...this.state.pumps];
      newPumps[pump].watering = true;
      var date = new Date();
      newPumps[pump].lastWatered = date.toISOString();
      this.setState({
        pumps: newPumps
      });
      // this[`ref_plant${pump+1}`].current.scrollIntoView();

      setTimeout(
        function() {
          turnOffWatering(pump);
        }.bind(this),
        Math.random() * 5000 + 1000
      );
    };

    const turnOffWatering = pump => {
      let newPumps = [...this.state.pumps];
      newPumps[pump].watering = false;
      // console.log('stateupdate!',this.state)
      this.setState(() => {
        return { pumps: newPumps };
      });
      // console.log("watering off", newPumps[pump].watering);
    };

    // ping the plants
    const pingOn = pump => {
      let newPumps = [...this.state.pumps];
      newPumps[pump].ping = true;
      // var date = new Date();
      // newPumps[pump].lastWatered = date.toISOString();
      this.setState(() => {
        return { pumps: newPumps };
      });
      console.log("pingon", newPumps[pump].watering);

      setTimeout(
        function() {
          pingOff(pump);
        }.bind(this),
        2000
      );
    };

    const pingOff = pump => {
      let newPumps = [...this.state.pumps];
      newPumps[pump].ping = false;
      // console.log('stateupdate!',this.state)
      this.setState(() => {
        return { pumps: newPumps };
      });
      // console.log("watering off", newPumps[pump].watering);
    };

    // setInterval(checkingTimer, 18000);
    setInterval(pingTimer, 4000);
  }

  componentDidUpdate() {
    shouldChange = true;
    let plantObject = this.state.pumps.find(item => item.watering);
    if (plantObject) {
      if (this[`ref_${plantObject.id}`]) {
        if (this[`ref_${plantObject.id}`].current) {
          this[`ref_${plantObject.id}`].current.scrollIntoView();
        }
      }
    }
  }

  render() {
    // console.log(this.state.pumps);
    let plantSelection;

    if (page == "/left") {
      plantSelection = this.state.pumps.filter(pump => pump.side === "left");
    } else if (page == "/right") {
      plantSelection = this.state.pumps.filter(pump => pump.side === "right");
    } else if (page == "/lower") {
      plantSelection = this.state.pumps.filter(
        pump => pump.side === "right" || pump.side === "left"
      );
    } else if (page == "/upper") {
      plantSelection = this.state.pumps.filter(pump => pump.side === "upper");
    } else {
      plantSelection = this.state.pumps;
      // console.log(plantSelection)
    }

    // const executeScroll = (ref) => scrollToRef(ref);
    // const scrollToRef = ref => {
    //   console.log('cki')
    //   if (ref && ref.current) {
    //     ref.current.scrollIntoView(); // General scroll to element function
    //   }
    // };
    return (
      <MainWrapper>
        <MainGrid>
          <WateringSection>
            <Box>
              <Card p={[1, 1, 2]} m={[1, 1, 2]} borderRadius={8}>
                <h1>Obtrusive Relationships</h1>
                {/* <Navigation>
                  <div>
                    <a href="/">All</a>
                  </div>
                  <div>
                    <a href="/upper">Upper St</a>
                  </div>
                  <div>
                    <a href="/left">Well Left</a>
                  </div>
                  <div>
                    <a href="/right">Well Right</a>
                  </div>
                </Navigation> */}
              </Card>
              {/* <Card p={[1, 1, 2]} m={[1, 1, 2]} borderRadius={8}>
              An installation that explores human de-centered design by using the waste generated by the LCC building to detoxify its surrounding environment and benefit nonhuman life.
              </Card> */}
            </Box>
            <Box
              p={[0, 1, 1]}
              fontSize={1}
              // color="darkgrey"
            >
              <Card pb={[0]} m={[1]} borderRadius={8}>
                <Environment>
                  <div>
                    temperature:{" "}
                    {this.state.temperature
                      ? fixed(this.state.temperature) + " \u2103"
                      : "loading"}
                  </div>
                  <div>
                    humidity:{" "}
                    {this.state.humidity
                      ? fixed(this.state.humidity) + " %"
                      : "loading"}
                  </div>
                  <div>
                    pressure:{" "}
                    {this.state.pressure
                      ? fixed(this.state.pressure) + " MB"
                      : "loading"}
                  </div>
                </Environment>
              </Card>
            </Box>
            <MiniSection>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["repeat(8, 1fr)"],
                  gridGap: 3 // theme.space[3]
                }}
              >
                {plantSelection.map(pump => {
                  return (
                    <PlantStatus
                      key={pump.id}
                      {...pump}
                      ref={this[`ref_${pump.id}`]}
                      mini
                    />
                  );
                })}
              </Box>
            </MiniSection>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: [
                  "repeat(2, 1fr)",
                  "repeat(2, 1fr)",
                  "repeat(2, 1fr)"
                ],
                gridGap: 3 // theme.space[3]
              }}
            >
              {plantSelection.map(pump => {
                return (
                  <PlantStatus
                    key={pump.id}
                    ref={this[`ref_${pump.id}`]}
                    {...pump}
                  />
                );
              })}
            </Box>
          </WateringSection>
          <DescriptionSection>
            {plantSelection.map((pump, i) => {
              // console.log('pump', i)
              return (
                <PlantDescription
                  key={pump.id}
                  ref={this[`ref_${pump.id}`]}
                  {...pump}
                  // onClick={scrollToRef(this[`ref_${pump.id}`])}
                />
              );
            })}
          </DescriptionSection>
        </MainGrid>
      </MainWrapper>
    );
  }
}

export default App;
