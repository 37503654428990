import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

require('typeface-roboto-mono')

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC4U2mo7JG6IO073av5qIYNf_KSZV7fGZI",
  authDomain: "piwatering-80e6c.firebaseapp.com",
  databaseURL: "https://piwatering-80e6c.firebaseio.com",
  projectId: "piwatering-80e6c",
  storageBucket: "",
  messagingSenderId: "29634894759",
  appId: "1:29634894759:web:a39ebbd81691e1a2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
